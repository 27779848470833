import React, { useState, useEffect, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import useMeasure from "react-use/lib/useMeasure";
import useWindowSize from "react-use/lib/useWindowSize";
import FocusLock from "react-focus-lock";

import modalStyles from "./Modal.module.css";
import modalBackgroundStyles from "./ModalBackground.module.css";
import Loader from "./Loader";
import Button from "./Button";

export default function Modal({
  id,
  title,
  body,
  actions,
  icon,
  children,
  narrow,
  medium,
  giant,
  fullScreen,
  loader,
  mainModalStyle,
  afterModal,
  allowBackgroundDimiss,
  onBackgroundDismiss,
  showDismissButton
}) {
  // Detect if modal is taller than viewport and convert to scrollable
  // modal as necessary.
  const { height: windowHeight } = useWindowSize();
  const [ref, { height: modalHeight }] = useMeasure();
  const longModalMode = showDismissButton && modalHeight > windowHeight - 100;

  const [open, setOpen] = useState(true);

  if (loader) {
    giant = true;
  }

  const onPop = useCallback(() => {
    onBackgroundDismiss();
  });

  useEffect(() => {
    if (allowBackgroundDimiss || showDismissButton) {
      window.history.pushState(
        {},
        "",
        `${window.location.pathname}${window.location.search || `?m=1`}`
      );
      window.addEventListener("popstate", onPop);
      return () => {
        window.removeEventListener("popstate", onPop);
      };
    }
  }, []);

  const onDismiss = () => {
    setOpen(false);
  };

  const onExited = () => {
    if (allowBackgroundDimiss || showDismissButton) {
      window.history.back();
    }
  };

  const modalClassName = classNames(
    "overflow-hidden shadow-xl transition ease-out duration-300 z-10 sm:w-full opacity-0 transform",
    fullScreen ? "" : "rounded-lg scale-95",
    giant
      ? ""
      : narrow
      ? "sm:max-w-sm"
      : medium
      ? "sm:max-w-md"
      : "sm:max-w-lg",
    giant ? "bg-black" : "bg-always-white px-6 pt-6 pb-5 sm:p-6 -mt-4 sm:mt-0"
  );
  const modalStyle = fullScreen
    ? { width: "100vw", height: "100vh" }
    : giant
    ? { width: "90vw", maxWidth: "160vh" }
    : longModalMode
    ? {
        margin: "0 auto"
      }
    : {
        overflowY: "auto",
        maxHeight: "calc(100vh - 2rem)"
      };

  const modalContent = (
    <CSSTransition
      in={open}
      timeout={300}
      unmountOnExit
      appear
      classNames={{ ...modalStyles }}
      onExited={onExited}
    >
      {loader ? (
        <div className="transition ease-out duration-300 z-10 opacity-0 transform scale-95">
          <Loader className="transform scale-150 text-always-white" />
        </div>
      ) : (
        <div
          className={modalClassName}
          style={Object.assign({}, modalStyle, mainModalStyle)}
          role="dialog"
          aria-modal="true"
          ref={ref}
        >
          {showDismissButton ? (
            <button
              className="absolute top-4 right-4 focus:outline-none text-always-gray-500 hover:text-always-gray-700 cursor-pointer"
              onClick={onDismiss}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : null}
          {children ? (
            children
          ) : (
            <React.Fragment>
              <div>
                {icon ? (
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    {icon === "check" ? (
                      <svg
                        className="h-6 w-6 text-green-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : null}
                  </div>
                ) : null}
                <div
                  className={classNames("text-center", icon && "mt-3 sm:mt-5")}
                >
                  {title ? (
                    <h3 className="text-lg leading-6 font-medium text-always-gray-900">
                      {title}
                    </h3>
                  ) : null}
                  <div className={title ? "mt-2" : ""}>
                    <div className="text-sm leading-5 text-always-gray-600">
                      {body}
                    </div>
                  </div>
                </div>
              </div>
              {actions && actions.length ? (
                <div className="mt-3 flex flex-wrap justify-center sm:mt-4">
                  {actions.map(({ title, primary, className, onClick }) => (
                    <Button
                      key={title}
                      onClick={onClick}
                      className={className}
                      secondary={!primary}
                    >
                      {title}
                    </Button>
                  ))}
                </div>
              ) : (
                <div className="mt-3 sm:mt-4">
                  <button
                    onClick={onDismiss}
                    type="button"
                    className="btn btn-blue py-2 mt-0"
                  >
                    Got it
                  </button>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </CSSTransition>
  );

  return (
    <div
      className={classNames(
        "fixed top-0 px-4 inset-0 z-50",
        longModalMode
          ? ""
          : "pt-4 sm:p-0 flex flex-col items-center justify-center"
      )}
    >
      <CSSTransition
        in={open}
        timeout={300}
        unmountOnExit
        appear
        classNames={{ ...modalBackgroundStyles }}
      >
        <div
          className="fixed inset-0 transition-opacity ease-out duration-300 opacity-0"
          onClick={allowBackgroundDimiss ? onDismiss : undefined}
        >
          <div
            className={classNames(
              "absolute inset-0 opacity-75",
              giant ? "bg-always-gray-900" : "bg-always-gray-500"
            )}
          ></div>
        </div>
      </CSSTransition>

      <FocusLock>
        {longModalMode ? (
          <div className="fixed inset-0 py-12 sm:py-24 px-4 overflow-auto">
            {modalContent}
          </div>
        ) : (
          // Placeholder div to not change react component tree when switching in and
          // out of long modal mode.
          <div>{modalContent}</div>
        )}
      </FocusLock>

      {afterModal && !loader ? (
        <CSSTransition
          in={open}
          timeout={300}
          unmountOnExit
          appear
          classNames={{ ...modalStyles }}
          onExited={onExited}
        >
          <div
            className={classNames(modalClassName, "shadow-none rounded-none")}
            style={Object.assign({}, modalStyle, {
              backgroundColor: "transparent"
            })}
          >
            {afterModal}
          </div>
        </CSSTransition>
      ) : null}

      {fullScreen ? <style>{`html, body { overflow: hidden; }`}</style> : null}
    </div>
  );
}
