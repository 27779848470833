import Modal from "./Modal";
import LoginModalInner from "./LoginModalInner";

export default function LoginModal({ onSuccess, targetResource, hideAccountCreation, ...rest }) {
  return (
    <Modal id="login" narrow={true} {...rest}>
      <LoginModalInner onSuccess={onSuccess} targetResource={targetResource} hideAccountCreation={hideAccountCreation} />
    </Modal>
  );
}
