import React, { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

export default function LanguageSwitcher() {
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLanguage(window.Localize?.getLanguage() || "en");
      window.Localize?.getAvailableLanguages((err, languages) => {
        setLanguages(languages);
      });
    }
  }, [typeof window]);

  useEffect(() => {
    const handler = data => {
      setLanguage(data.to);
    };

    window.Localize?.on("setLanguage", handler);
    return () => window.Localize?.off("setLanguage", handler);
  }, []);

  if (!languages.length || !language) {
    return null;
  }

  return (
    <div className="Eventive__LanguageSwitcher__container text-center mb-4">
      <select
        name="Eventive__LanguageSwitcher__select"
        className="border border-gray-400 bg-gray-700 text-white px-2 py-1 text-sm rounded-md focus:outline-none focus:shadow-outline-gray"
        value={language}
        onChange={e => {
          window.Localize?.setLanguage(e.target.value);
          moment.locale(e.target.value);
        }}
      >
        {languages.map(language => {
          return (
            <option key={language.code} value={language.code}>
              {language.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
