import { useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import styles from "./DropdownButton.module.css";
import ClickAwayListener from "./ClickAwayListener";

export default function DropdownButton({ button, dropdown, className }) {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classNames("relative inline-block text-left", className)}>
        <span onClick={() => setOpen(!open)}>{button}</span>
        <CSSTransition
          in={open}
          timeout={200}
          unmountOnExit
          mountOnEnter
          classNames={{ ...styles }}
          onMouseUp={() => setOpen(false)}
        >
          {dropdown}
        </CSSTransition>
      </div>
    </ClickAwayListener>
  );
}
